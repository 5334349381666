// Generated by Framer (d894e1b)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IkNIg_8SE"];

const serializationHash = "framer-G0GBo"

const variantClassNames = {IkNIg_8SE: "framer-v-w5dszv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IkNIg_8SE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-G0GBo", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-w5dszv", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IkNIg_8SE"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-mqyl25"} data-framer-name={"Tesla"} fill={"black"} intrinsicHeight={46} intrinsicWidth={350} layoutDependency={layoutDependency} layoutId={"A8GeV7HUz"} svg={"<svg width=\"350\" height=\"46\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\" fill=\"#ADBDCE\"><path d=\"M299.014 18.182v27.502h8.791V27.35h32.149v18.335h8.791V18.182h-49.731Zm7.786-8.917h33.907c4.772-.879 8.163-5.149 9.168-9.167h-52.243c1.005 4.018 4.521 8.163 9.168 9.167Zm-34.535 36.42c4.395-1.884 6.781-5.15 7.786-8.917h-39.559V.223h-8.916v45.461h40.689ZM165.644 9.14h31.396c4.772-1.382 8.666-5.024 9.67-8.917h-49.731v26.875h40.689v9.419H165.77c-5.023 1.381-9.293 4.772-11.428 9.167h52.117V18.182H165.77l-.126-9.042Zm-77.359.125h33.908c4.772-.879 8.288-5.149 9.167-9.167H78.992c1.005 4.018 4.521 8.163 9.293 9.167Zm0 17.959h33.908c4.772-.88 8.288-5.15 9.167-9.168H78.992c1.005 4.019 4.521 8.163 9.293 9.168Zm0 18.46h33.908c4.772-.879 8.288-5.149 9.167-9.167H78.992c1.005 4.018 4.521 8.288 9.293 9.167ZM0 .223c1.005 4.019 4.521 8.038 9.168 9.042h14.316l.753.251V45.56h8.917V9.516l.753-.25h14.317c4.772-1.257 8.163-5.024 9.168-9.043V.098L0 .223Z\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" transform=\"translate(0 .07)\" d=\"M0 0h350v45.64H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-G0GBo [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G0GBo .framer-1fykc7b { display: block; }", ".framer-G0GBo .framer-w5dszv { height: 39px; overflow: hidden; position: relative; width: 297px; }", ".framer-G0GBo .framer-mqyl25 { aspect-ratio: 7.608695652173913 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 39px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 297
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerMnKoYZLIE: React.ComponentType<Props> = withCSS(Component, css, "framer-G0GBo") as typeof Component;
export default FramerMnKoYZLIE;

FramerMnKoYZLIE.displayName = "Tesla";

FramerMnKoYZLIE.defaultProps = {height: 39, width: 297};

addFonts(FramerMnKoYZLIE, [])